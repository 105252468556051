import { gql } from "@apollo/client"

const getProducts = gql`
  {
    products(first: 250) {
      nodes {
        id
        title
        tags
        variants(first: 100) {
          nodes {
            id
          }
        }
        priceRange {
          maxVariantPrice {
            amount
          }
        }
      }
    }
  }
`

export default getProducts
