import { gql } from "@apollo/client"

const cartNoteUpdate = gql`
  mutation updateCart($cartId: ID!, $note: String!) {
    cartNoteUpdate(cartId: $cartId, note: $note) {
      cart {
        id
        note
      }
    }
  }
`

export default cartNoteUpdate
