import { ApolloProvider } from "@apollo/client"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { SpeedInsights } from "@vercel/speed-insights/next"
import type { AppProps } from "next/app"
import dynamic from "next/dynamic"
import "react-loading-skeleton/dist/skeleton.css"
import "react-multi-carousel/lib/styles.css"
import "react-toastify/dist/ReactToastify.css"

// import dayjs from "dayjs"
import client from "@/lib/shopify/client"

import ContextProvider from "@/Context/ContextProvider"

import Footer from "@/components/Footer"
import NavbarV2 from "@/components/NavbarV2"

import "@/styles/fonts.css"
import "@/styles/inclusive-approach.css"
import "@/styles/tailwind.css"

const Cart = dynamic(() => import("@/components/Cart"))
const BaseComponents = dynamic(() => import("@/components/BaseComponents"))

// import { useEffect } from "react"
// import { useRouter } from "next/router"
// import Script from "next/script"

const queryClient = new QueryClient()

function MyApp({ Component, pageProps }: AppProps) {
  // useEffect(() => {
  //   import("react-facebook-pixel")
  //     .then((x) => x.default)
  //     .then((ReactPixel) => {
  //       ReactPixel.init("725295402411553")
  //       ReactPixel.pageView()

  //       router.events.on("routeChangeComplete", () => {
  //         ReactPixel.pageView()
  //       })
  //     })
  // }, [router.events])

  // useEffect(() => {
  //@ts-ignore
  // window.dayjs = dayjs
  // ReactModal.setAppElement("#__next")
  // }, [])

  return (
    <>
      {/* <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-W7514JTJDG"
      ></Script>
      <Script
        id="google-analytics"
        strategy="afterInteractive"
      >{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-N63Q8MT6');`}</Script>
      <Script id="google-analytics" strategy="afterInteractive">
        {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-W7514JTJDG');`}
      </Script> */}
      <ApolloProvider client={client}>
        {/* <FacebookPixel /> */}
        <QueryClientProvider client={queryClient}>
          <ContextProvider>
            <NavbarV2 />
            <Cart />
            <Component {...pageProps} />
            <Footer />
            <BaseComponents />
          </ContextProvider>
        </QueryClientProvider>
        <SpeedInsights />
      </ApolloProvider>
    </>
  )
}

export default MyApp
