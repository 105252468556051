import { gql } from "@apollo/client"

const getCoachingPackages = gql`
  query Products {
    collection(id: "gid://shopify/Collection/439467311399") {
      products(first: 100) {
        nodes {
          title
          tags
          variants(first: 100) {
            nodes {
              id
            }
          }
          priceRange {
            maxVariantPrice {
              amount
            }
          }
        }
      }
    }
  }
`

export default getCoachingPackages
