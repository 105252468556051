import { RefObject, useEffect, useRef, useState } from "react"

const useInView = (
  options: IntersectionObserverInit = {},
): [boolean, RefObject<HTMLDivElement>] => {
  const [isInView, setIsInView] = useState(false)
  const [hasLCP, setHasLCP] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent)

    // PerformanceObserver for LCP (only for non-mobile devices)
    if (!isMobile) {
      const lcpObserver = new PerformanceObserver((list) => {
        const entries = list.getEntries()
        const lastEntry = entries[entries.length - 1]

        if (lastEntry.entryType === "largest-contentful-paint") {
          setHasLCP(true)
        }
      })

      lcpObserver.observe({ type: "largest-contentful-paint", buffered: true })

      return () => {
        lcpObserver.disconnect()
      }
    } else {
      // On mobile devices, set LCP after 1 second
      const timeout = setTimeout(() => {
        setHasLCP(true)
      }, 1000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasLCP) {
          setIsInView(true)
        }
      },
      { threshold: 0.3, ...options },
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [options, hasLCP])

  return [isInView, ref]
}

export default useInView
