import { gql } from "@apollo/client"

const getCart = gql`
  query getCart($cartId: ID!) {
    cart(id: $cartId) {
      id
      note
      lines(first: 50) {
        edges {
          node {
            id
            attributes {
              key
              value
            }
            quantity
            merchandise {
              ... on ProductVariant {
                id
                title
                priceV2 {
                  amount
                  currencyCode
                }
                image {
                  url
                  altText
                  width
                  height
                }
                product {
                  id
                  title
                  handle
                }
              }
            }
          }
        }
      }
      cost {
        subtotalAmount {
          amount
          currencyCode
        }
      }
      checkoutUrl
    }
  }
`

export default getCart
