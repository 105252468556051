import LinkedInIcon from "@/public/icons/linkedin.svg"
import TikTokIcon from "@/public/icons/tiktok.svg"

import Link from "next/link"
import { FC } from "react"

import useInView from "@/lib/hooks/useInView"

const footerLinks1 = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Perform",
    url: "/perform",
  },
  {
    name: "Coaches",
    url: "/all-trainers",
  },
  {
    name: "Recover",
    url: "/recover",
  },
  {
    name: "Sleep",
    url: "/sleep",
  },
  {
    name: "Career",
    url: "/job-enquiries",
  },
  {
    name: "Blogs",
    url: "/blogs",
  },
]

const footerLinks2 = [
  {
    name: "Terms & Conditions",
    url: "/terms-conditions",
  },
  {
    name: "My Account",
    url: "/user/profile",
  },
  {
    name: "Meal Plans",
    url: "/nutrition-shop",
  },
  {
    name: "Cafe",
    url: "/cafe",
  },
  {
    name: "Events",
    url: "/events",
  },
  {
    name: "Contact Us",
    url: "/contact-us",
  },
]

const Footer: FC = () => {
  const [isInView, divRef] = useInView()

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <footer>
      <div ref={divRef} className="group">
        <div className="flex flex-wrap items-center justify-center gap-12 transition-colors duration-150 ease-out py-9 group-hover:bg-white bg-brand">
          {isInView && (
            <>
              <a
                aria-label="Body Factory Bali Instagram"
                target="_blank"
                href="https://www.instagram.com/bodyfactory_bali/"
              >
                <img
                  loading="lazy"
                  src="/icons/instagram-black.svg"
                  alt="instagram"
                  className="inline-block group-hover:hidden"
                />
                <img
                  loading="lazy"
                  src="/icons/instagram-yellow.svg"
                  alt="instagram"
                  className="hidden group-hover:inline-block"
                />
              </a>
              <a
                aria-label="Body Factory Bali Facebook"
                target="_blank"
                href="https://www.facebook.com/bodyfactorybali/"
              >
                <img
                  loading="lazy"
                  className="inline-block group-hover:hidden"
                  src="/icons/facebook-black.svg"
                  alt="facebook"
                />
                <img
                  loading="lazy"
                  className="hidden group-hover:inline-block"
                  src="/icons/facebook-yellow.svg"
                  alt="facebook"
                />
              </a>
              <a
                aria-label="Body Factory Bali Youtube"
                href="https://www.youtube.com/channel/UCJ4KYRsyx63Jqon1qQbwkIw"
                target="_blank"
              >
                <img
                  loading="lazy"
                  className="inline-block group-hover:hidden"
                  src="/icons/youtube-black.svg"
                  alt="youtube"
                />
                <img
                  loading="lazy"
                  className="hidden group-hover:inline-block"
                  src="/icons/youtube-yellow.svg"
                  alt="youtube"
                />
              </a>
              <a
                aria-label="Body Factory Bali Instagram"
                target="_blank"
                href="https://id.linkedin.com/company/body-factorybali"
              >
                <LinkedInIcon
                  height={40}
                  width={40}
                  className={"text-[black] group-hover:text-[#FFCC00]"}
                />
              </a>
              <a
                aria-label="Body Factory Bali Instagram"
                target="_blank"
                href="https://www.tiktok.com/@bodyfactory.bali"
              >
                <TikTokIcon
                  height={40}
                  width={40}
                  className={"text-[black] group-hover:text-[#FFCC00]"}
                />
              </a>
            </>
          )}
        </div>
      </div>
      <div className="pt-[102px] text-center lg:text-left text-lg text-white pb-[114px] bg-text px-[25px]">
        <div className="mx-auto lg:max-w-[80%] items-center lg:justify-between flex flex-col lg:flex-row">
          <div className="flex flex-col gap-[60px] lg:flex-row">
            <div className="flex justify-center">
              {isInView && (
                <img
                  loading="lazy"
                  height={99}
                  className="h-[99px]"
                  src="/logo.png"
                  alt="logo"
                />
              )}
            </div>
            <div className="max-w-[300px]">
              <address>
                Body Factory Bali Jl. Nelayan No.27, Canggu, Kec. Kuta Utara,
                Kabupaten Badung, Bali 80361
              </address>
              <div className="my-[41px] lg:mb-[78px]">
                <div>
                  <b>Opening Hours</b>
                </div>
                <div>6am - 10pm all week</div>
              </div>
              <u className="block mb-4">E : info@bodyfactorybali.com</u>
              <u className="block">P : + 62 811 3802 255</u>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:gap-[100px] gap-[34px]">
            <div className="space-y-[34px]">
              {footerLinks1.map((link) => (
                <Link
                  aria-label={link.name}
                  className="block"
                  key={link.name}
                  href={link.url}
                >
                  {link.name}
                </Link>
              ))}
            </div>
            <div className="space-y-[34px]">
              {footerLinks2.map((link) => (
                <Link
                  aria-label={link.name}
                  className="block"
                  key={link.name}
                  href={link.url}
                >
                  {link.name}
                </Link>
              ))}
            </div>
            <div>
              <button aria-label="Scroll to top" onClick={scrollToTop}>
                {isInView && (
                  <img
                    loading="lazy"
                    src="/icons/arrow-up.svg"
                    alt="scroll to top"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
