import { ApolloClient, InMemoryCache } from "@apollo/client"

const client = new ApolloClient({
  uri: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_DOMAIN,
  cache: new InMemoryCache(),
  headers: {
    "X-Shopify-Storefront-Access-Token":
      process.env.NEXT_PUBLIC_SHOPIFY_ACCESS_TOKEN!,
  },
})

export default client
