import { ReactNode } from "react"

import { AuthProvider } from "@/Context/auth.context"
import { CartProvider } from "@/Context/cart.context"
import { CoachingPackagesProvider } from "@/Context/coachingPackages.context"
import { NotificationsProvider } from "@/Context/notifications.context"
import { ProductsProvider } from "@/Context/products.context"

const ContextProvider = ({ children }: { children: ReactNode }) => {
  return (
    <AuthProvider>
      <NotificationsProvider>
        <CoachingPackagesProvider>
          <ProductsProvider>
            <CartProvider>{children}</CartProvider>
          </ProductsProvider>
        </CoachingPackagesProvider>
      </NotificationsProvider>
    </AuthProvider>
  )
}

export default ContextProvider
