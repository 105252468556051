import { useLazyQuery, useMutation } from "@apollo/client"
import { v4 as uuid } from "uuid"

import { useRouter } from "next/router"
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"

import cartCreate from "@/lib/shopify/cartCreate.mutation"
import cartLinesAdd from "@/lib/shopify/cartLinesAdd.mutation"
import cartLinesRemove from "@/lib/shopify/cartLinesRemove.mutation"
import cartNoteUpdate from "@/lib/shopify/cartNoteUpdate.mutation"
import cartUpdate from "@/lib/shopify/cartUpdate.mutation"
import getCart from "@/lib/shopify/getCart.query"
import { Cart } from "@/lib/typings/Cart"

interface ICart {
  cartId: string | undefined
  cart: Cart | undefined
  cartOpen: boolean
  openCart: () => void
  closeCart: () => void
  // eslint-disable-next-line no-unused-vars
  addToCart: (args: {
    id: string
    attributes?: { key: string; value: string }
    quantity?: number
    shouldOpenCart?: boolean
  }) => void
  // eslint-disable-next-line no-unused-vars
  removeItem: (lineId: string) => void
  getCart: () => void
  // eslint-disable-next-line no-unused-vars
  quantityUpdate: (Args: { lineId?: string; quantity: number }) => void
  createNewCart: () => Promise<void>
  updateToken: () => Promise<string>
  setFetchData: (value: boolean) => void
}

const CartContext = createContext<ICart>({
  cartId: undefined,
  cart: undefined,
  cartOpen: false,
  addToCart() {},
  openCart() {},
  closeCart() {},
  removeItem() {},
  getCart() {},
  quantityUpdate() {},
  async createNewCart() {},
  async updateToken() {
    return ""
  },
  setFetchData() {},
})

export const CartProvider: FC<PropsWithChildren> = ({ children }) => {
  const [cartId, setCartId] = useState<string | undefined>(undefined)
  const [cart, setCart] = useState<Cart | undefined>(undefined)
  const [cartOpen, setCartOpen] = useState(false)
  const router = useRouter()

  const [fetchData, setFetchData] = useState(false)

  const [getCartQuery] = useLazyQuery<{ cart: Cart }>(getCart, {
    onCompleted(data) {
      setCart(data.cart)
    },
    onError(error) {
      console.log(error)
    },
  })

  const [createCart] = useMutation<{
    cartCreate: {
      cart: Cart
    }
  }>(cartCreate, {
    onCompleted(data) {
      // console.log(data)
      if (
        router.pathname !== "/signup" &&
        data.cartCreate.cart.lines.edges.length > 0
      ) {
        openCart()
      }
      window.localStorage.setItem("cartId", data.cartCreate.cart.id)
      setCartId(data.cartCreate.cart.id)
      setCart(() => data.cartCreate.cart)
    },
  })

  const [addToCartLines] = useMutation<{
    cartLinesAdd: {
      cart: Cart
    }
  }>(cartLinesAdd, {
    onCompleted(data) {
      if (router.pathname !== "/signup") {
        openCart()
      }
      setCart(() => data.cartLinesAdd.cart)
    },
  })

  const [removeItemFromCart] = useMutation(cartLinesRemove, {
    onCompleted() {
      getCartQuery({
        variables: {
          cartId,
        },
      })
    },
  })

  const [cartLineUpdate] = useMutation(cartUpdate, {
    onCompleted() {
      getCartQuery({
        variables: {
          cartId,
        },
      })
    },
  })

  const [updateCartNote] = useMutation(cartNoteUpdate)

  const quantityUpdate = ({
    lineId,
    quantity,
  }: {
    lineId?: string
    quantity: number
  }) => {
    if (!cartId || !lineId) return
    cartLineUpdate({
      variables: {
        cartId,
        lines: [
          {
            id: lineId,
            quantity,
          },
        ],
      },
    })
  }

  const createNewCart = useCallback(async () => {
    // let token  = window.localStorage.getItem("cart-token")
    // if(!token){

    // }
    const token = uuid()
    await createCart({
      variables: {
        lines: [],
        token,
      },
    })
  }, [createCart])

  const addToCart: ICart["addToCart"] = ({ id, attributes, quantity = 1 }) => {
    const obj: { [key: string]: any } = {}
    obj["merchandiseId"] = id
    obj["quantity"] = quantity
    if (attributes) {
      obj["attributes"] = attributes
    }
    // console.log({ cartId })
    if (!cartId) {
      // console.log("creating cart")
      createCart({
        variables: {
          lines: [obj],
        },
      })
    } else {
      // console.log("adding to cart")
      addToCartLines({
        variables: {
          cartId,
          lines: [obj],
        },
      })
    }
  }

  const removeItem = (lineId: string) => {
    removeItemFromCart({
      variables: {
        cartId,
        lineIds: [lineId],
      },
    })
  }

  // useEffect(() => {
  //   if ((cart?.lines.edges.length || 0) === 0) {
  //     createNewCart()
  //   }
  // })

  useEffect(() => {
    const localCartId = window.localStorage.getItem("cartId")
    // const julTenth2024 = new Date("2024-07-09")
    const isAfterUpdate = Boolean(window.localStorage.getItem("isAfterUpdate"))

    if (!localCartId || !isAfterUpdate) {
      createNewCart()
      window.localStorage.setItem("isAfterUpdate", "true")
      return
    } else {
      setCartId(localCartId)
    }
  }, [createNewCart])

  useEffect(() => {
    if (!fetchData) return
    if (!cartId) {
      setCart(undefined)
    } else {
      // console.log({ cartId })
      getCartQuery({
        variables: {
          cartId,
        },
      })
    }
  }, [cartId, getCartQuery, fetchData])

  // useEffect(() => {
  //   // console.log({ cart })
  // }, [cart])

  const updateToken = async () => {
    const token = uuid()
    await updateCartNote({
      variables: {
        cartId,
        note: token,
      },
    })
    return token
  }

  const openCart = () => {
    setCartOpen(true)
  }
  const closeCart = () => {
    setCartOpen(false)
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        cartId,
        removeItem,
        cartOpen,
        addToCart,
        openCart,
        closeCart,
        getCart: getCartQuery,
        quantityUpdate,
        createNewCart,
        updateToken,
        setFetchData,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export const useCart = () => {
  return useContext(CartContext)
}
