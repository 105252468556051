import { ApolloError, useQuery } from "@apollo/client"

import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react"

import getCoachingPackages from "@/lib/shopify/getCoachingPackages.query"

type Package = {
  [key: string]: {
    title: string
    id: string
    amount: string
    tags: string[]
  }[]
}

interface ICoachingPackagesContext {
  packages: Package
  loading: boolean
  error: ApolloError | undefined
  // eslint-disable-next-line no-unused-vars
  setFetchData: (value: boolean) => void
}

interface QueryReturnValue {
  collection: {
    products: {
      nodes: {
        title: string
        tags: string[]
        variants: {
          nodes: {
            id: string
          }[]
        }
        priceRange: {
          maxVariantPrice: {
            amount: string
          }
        }
      }[]
    }
  }
}

const CoachingPackagesContext = createContext<ICoachingPackagesContext>({
  packages: {},
  loading: false,
  error: undefined,
  setFetchData() {},
})

export const CoachingPackagesProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [packages, setPackages] = useState<
    ICoachingPackagesContext["packages"]
  >({})
  const [fetchData, setFetchData] = useState(false)
  const { loading, error } = useQuery<QueryReturnValue>(getCoachingPackages, {
    skip: !fetchData,
    onCompleted(data) {
      // console.log({ data })
      const res: ICoachingPackagesContext["packages"] = {}
      data.collection.products.nodes.forEach((node) => {
        const tier = node.tags.find((tag) => tag.includes("tier"))!
        const amount = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(Number(node.priceRange.maxVariantPrice.amount))
        if (!res[`${tier}`]) {
          res[`${tier}`] = []
        }
        res[`${tier}`].push({
          title: `${node.title} ${tier}`,
          id: node.variants.nodes[0].id,
          amount,
          tags: node.tags,
        })
      })
      Object.keys(res).forEach((key) => {
        res[key].sort((a, b) => {
          const aSession = a.tags
            .find((tag) => tag.includes("session"))!
            .split(" ")[0]
          const bSession = b.tags
            .find((tag) => tag.includes("session"))!
            .split(" ")[0]
          return Number(aSession) - Number(bSession)
        })
      })
      setPackages(res)
    },
  })

  return (
    <CoachingPackagesContext.Provider
      value={{
        packages,
        loading,
        error,
        setFetchData,
      }}
    >
      {children}
    </CoachingPackagesContext.Provider>
  )
}

export const useCoachingPackages = () => {
  return useContext(CoachingPackagesContext)
}
