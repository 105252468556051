import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"

import { useEffect, useState } from "react"

const firebaseConfig = {
  apiKey: "AIzaSyBkuosi22u1JLPs1j-arlKt9vDwdR0D-pI",
  authDomain: "balibodyfactory.firebaseapp.com",
  projectId: "balibodyfactory",
  storageBucket: "balibodyfactory.appspot.com",
  messagingSenderId: "543815508107",
  appId: "1:543815508107:web:ecbb1c652efaabcb6f748e",
  measurementId: "G-V9H8312Z47",
}

// let app: any, auth: any, db: any

const useFirebase = () => {
  const [firebaseInitialized, setFirebaseInitialized] = useState(false)
  const [shouldInit, setShouldInit] = useState(false)
  const [app, setApp] = useState<any>(null)
  const [auth, setAuth] = useState<any>(null)
  const [db, setDb] = useState<any>(null)

  useEffect(() => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent)

    if (!isMobile) {
      // LCP Observer for non-mobile devices
      const observer = new PerformanceObserver((list) => {
        const entries = list.getEntries()
        const lastEntry = entries[entries.length - 1]

        if (lastEntry.entryType === "largest-contentful-paint") {
          setShouldInit(true)
        }
      })

      observer.observe({ type: "largest-contentful-paint", buffered: true })

      return () => {
        observer.disconnect()
      }
    } else {
      // On mobile devices, set shouldInit to true after 1 second
      const timeout = setTimeout(() => {
        setShouldInit(true)
      }, 1000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [])

  // console.log({ shouldInit, firebaseInitialized, auth })
  useEffect(() => {
    if (!shouldInit) return
    if (!firebaseInitialized) {
      const initializeFirebase = async () => {
        // app = initializeApp(firebaseConfig)
        // auth = getAuth(app)
        // db = getFirestore(app)
        const initApp = initializeApp(firebaseConfig)
        setApp(initApp)
        setAuth(getAuth(initApp))
        setDb(getFirestore(initApp))
        setFirebaseInitialized(true)
      }

      initializeFirebase()
    }
  }, [firebaseInitialized, shouldInit])

  return { app, auth, db }
}

export default useFirebase
